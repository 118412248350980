
import {
  computed, defineComponent, onMounted, ref, toRefs, watch,
} from 'vue';
import 'scorm-again';
import { ScormPlayerView } from '@/domains/scorm/components';
import AtomPlayerSidebar from '@/components/ui/AtomSidebar/AtomPlayerSidebar.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import { IBreadcrumb } from '@/domains/common';
import useScormPlayer from '@/domains/scorm/composables/useScormPlayer';
import store from '@/plugins/vuex';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';
import { useDi } from '@/plugins/di';
import { AtomType } from '@/domains/atom';
import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';

export default defineComponent({
  name: 'AssignmentScormPlayer',

  components: {
    TTBackButton,
    UpButton,
    ScormPlayerView,
    AtomPlayerSidebar,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const { t } = useI18n();
    const di = useDi();
    const { playerSessionId } = toRefs(props);
    const isComponentLoading = ref(true);

    const {
      content,
      isLoading: isScormContentLoading,
      isUpToDate,
      isSessionCompleted,
      fetchSession,
      onUpdateProgressHandler,
      checkVersion,
      onUpdateStatusHandler,
    } = useScormPlayer({ playerSessionId }, context);

    const {
      isLoading: isAssignmentLoading,
      catalogueAtomVersionId,
      fetchAssignmentBySession,
    } = useAssignment({ playerSessionId, atomType: AtomType.SCORM });

    const {
      atom,
      isLoading: isAtomLoading,
      fetchAtom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const companyId = computed<boolean>(() => store.getters['company/companyId']);

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-learning-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'material-name-link',
        disabled: true,
      },
    ]);

    const sendAnalytic = (eventValue: string, eventLabel: string) => {
      const data = {
        companyId: companyId.value,
        playerSessionId: playerSessionId.value,
      };
      di.tmt.sendEvent('send', 'click', eventValue, eventLabel, 'internallLink', data);
    };

    const backButtonClickHandler = () => {
      sendAnalytic(t('TTBackButton.title'), 'go-back');
    };

    const step = computed(() => ({
      atomType: AtomType.SCORM,
      name: atom.value?.name,
      isCompleted: isSessionCompleted.value,
      id: atom.value?.id ?? '',
    }));

    const isLoading = computed(() => (
      isComponentLoading.value
        || isScormContentLoading.value
        || isAssignmentLoading.value
        || isAtomLoading.value));

    const init = async () => {
      Promise.all([
        fetchSession(),
        checkVersion(),
        fetchAssignmentBySession()
          .then(fetchAtom),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isComponentLoading.value = false;
        });
    };

    watch(playerSessionId, async () => init());
    onMounted(async () => init());

    return {
      content,
      isLoading,
      breadcrumbs,
      isUpToDate,
      step,
      atom,
      sendAnalytic,
      onUpdateProgressHandler,
      backButtonClickHandler,
      onUpdateStatusHandler,
    };
  },
});
