
import { computed, defineComponent, PropType } from 'vue';
import { IAtom, AtomEntity, AtomType } from '@/domains/atom';
import { IScormContent } from '@/services/api/lxp-scorm/types';
import ProvidersButton from '@/components/ui/ProvidersButton';
import ScormViewDescription from '@/views/App/Assignments/Scorm/ScormView/ScormViewDescription.vue';
import SeparateAtomNewVersionBanner from '@/components/ui/SeparateAtomNewVersionBanner';
import ScormIFrame from '@/domains/scorm/ScormIFrame.vue';
import { debounce } from '@/helpers';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'ScormPlayerView',

  components: {
    ProvidersButton,
    ScormViewDescription,
    SeparateAtomNewVersionBanner,
    ScormIFrame,
  },

  props: {
    atom: {
      type: Object as PropType<IAtom>,
      default: () => (new AtomEntity({})),
    },
    content: {
      type: Object as PropType<IScormContent>,
      required: true,
    },
    isUpToDate: {
      type: Boolean,
      default: true,
    },
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    const title = computed(() => props.atom.name || t('Assignment.Course.defaultTrackName'));
    const description = computed(() => props.atom.description || '');
    const providers = computed(() => props.atom.providers ?? []);

    const updateScorm = (data: string) => emit('update:progress', data);
    const updateScormDebounced = debounce(updateScorm, 300);

    const changeStatus = (status: string) => {
      emit('update:status', status);
    };

    return {
      title,
      description,
      AtomType,
      updateScormDebounced,
      providers,
      changeStatus,
    };
  },
});
